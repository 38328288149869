import { ResponseHelperService } from './../services/helpers/response-helper.service';
import { MemberRelationship, Manifest } from 'src/app/data/class';
import {
  ManifestFieldChevronList,
  CustomAttribute,
  SegmentMapping,
  MemberInstrument,
} from './../data/class';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { IntegrationsService } from './../services/integrations.service';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Location } from '@angular/common';
import {
  faChevronUp,
  faChevronDown,
  faTimes,
  faSave,
  faRedo,
  faTrash,
  faPlus,
  faChevronRight,
  faArrowsAltV,
} from '@fortawesome/free-solid-svg-icons';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbAccordionDirective, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.scss'],
})
export class ManifestComponent implements OnInit {
  @ViewChildren(NgSelectComponent)
  ngSelectComponentList: QueryList<NgSelectComponent>;
  @ViewChildren('accMain') ngbAccordionList: QueryList<NgbAccordionDirective>;
  dropdownDisplay: any[] = [];
  selectedField: any = [];
  selectedEditField: any = [];
  fieldMappings: any[];
  manifestType = '';
  model: Manifest[] = [];
  packagedModel: Manifest[] = [];
  manifest: any;
  displayNames: any;
  rawJSON = '';
  integrationId: number;
  unsavedChanges = false;
  addImportFileType: string;

  chevronList: boolean[] = [];
  openFieldChevronList: ManifestFieldChevronList =
    new ManifestFieldChevronList();
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faArrowsAltV = faArrowsAltV;
  faTimes = faTimes;
  faTrash = faTrash;
  faSave = faSave;
  faRedo = faRedo;
  faPlus = faPlus;

  importTypes = [
    { key: 'ELIGIBILITY', name: 'Eligibility' },
    { key: 'TRANSACTION', name: 'Transaction' },
    { key: 'ORGANIZATION', name: 'Organization' },
  ];

  constructor(
    private integrationsService: IntegrationsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private responseHelperService: ResponseHelperService,
    private location: Location,
    private modalHelper: NgbModal
  ) {}

  ngOnInit(): void {
    this.integrationId = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    this.integrationsService
      .getIntegration(this.integrationId, { format: 'json' })
      .subscribe((response) => {
        if (response.entity.manifest !== null) {
          this.model = JSON.parse(response.entity.manifest).importFile;
          for (const importFile of this.model) {
            this.setData(importFile);
          }
        }
      });
  }

  checkKeysMapping(keys: string[], keysMapping: string[]): boolean {
    return keys.some((key) => keysMapping.includes(key));
  }

  setData(importFile: Manifest): Manifest {
    importFile.selectedField = [];
    importFile.selectedEditField = [];
    importFile.selectedComplexType = [];
    importFile.dropdownDisplay = [];
    importFile.expandedLookup = false;
    importFile.openFieldChevronList = new ManifestFieldChevronList();
    importFile.openFieldChevronList.fieldPropertyIndexes = {};
    importFile.complexTypesDropdown = [];
    importFile.selectedType = [];

    const keys = Object.keys(importFile.fieldMappings);

    if (
      importFile.importType?.toLowerCase() === 'transaction' ||
      this.checkKeysMapping(keys, ['transaction', 'txnInstrument'])
    ) {
      this.manifestType = 'transaction';
      importFile.importType = 'TRANSACTION';
      importFile.fieldMappingDefinition = this.assignTransactionMappings();
    } else if (
      importFile.importType?.toLowerCase() === 'organization' ||
      this.checkKeysMapping(keys, ['organization', 'organizationAddress'])
    ) {
      this.manifestType = 'organization';
      importFile.importType = 'ORGANIZATION';
      importFile.fieldMappingDefinition = this.assignOrganizationMappings();
    } else {
      this.manifestType = 'eligibility';
      importFile.importType = 'ELIGIBILITY';
      importFile.fieldMappingDefinition = this.assignMemberMappings(
        importFile.importType?.toLowerCase()
      );
    }

    this.formatDisplayNames(importFile);

    importFile.fieldMappingDefinition.forEach((mapping) => {
      importFile.selectedField[mapping.id] = null;
      importFile.selectedEditField[mapping.id] = null;
      importFile.dropdownDisplay[mapping.id] = mapping.fields;
      if (mapping.complexTypes) {
        mapping.complexTypes.forEach((complexType) => {
          importFile.complexTypesDropdown[mapping.id] = complexType.anyOf;
          importFile.typeFields = complexType.anyOf;
        });
      }
    });

    Object.keys(importFile.fieldMappings).forEach((mapping) => {
      importFile.fieldMappings[mapping].forEach((field) => {
        this.removeFromDropdown(importFile, mapping, field);
      });
    });

    return importFile;
  }

  isFieldIndexUsed(importFile: Manifest, idx: number): boolean {
    if (!idx) {
      return false;
    }
    const fields = {
      fieldMappings: importFile.fieldMappings,
      memberInstrument: importFile.memberInstrument,
      customAttribute: importFile.customAttribute,
      segmentMapping: importFile.segmentMapping,
      memberRelationship: importFile.memberRelationship,
    };

    for (const property in fields) {
      if (importFile.hasOwnProperty(property)) {
        for (const inner in importFile[property]) {
          if (typeof importFile[property][inner].forEach === 'function') {
            for (const fieldMapping in importFile[property][inner]) {
              if (importFile[property][inner][fieldMapping].fieldMappings) {
                importFile[property][inner][fieldMapping].fieldMappings.forEach(
                  (chieldFieldMapping) => {
                    if (chieldFieldMapping.fieldIndex === idx) {
                      this.toastr.warning('Field index already used');
                      return true;
                    }
                  }
                );
              } else {
                if (
                  importFile[property][inner][fieldMapping].fieldIndex === idx
                ) {
                  this.toastr.warning('Field index already used');
                  return true;
                }
              }
            }
          } else {
            if (importFile[property][inner].fieldIndex === idx) {
              this.toastr.warning('Field index already used');
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  getParams(fields: any, param: any): any {
    const list = [];
    fields.forEach((element) => {
      list.push(element[param]);
    });
    return list;
  }

  removeFromDropdown(
    importFile: Manifest,
    propertyId: string,
    field: any
  ): void {
    let index;
    importFile.dropdownDisplay[propertyId]?.forEach((propField, idx) => {
      if (propField.id === field.entityProp) {
        index = idx;
      }
    });
    importFile.dropdownDisplay[propertyId]?.splice(index, 1);
    importFile.dropdownDisplay[propertyId] = importFile.dropdownDisplay[
      propertyId
    ]?.filter((option: any) => {
      return option.id !== field.entityProp;
    });
    this.ngSelectComponentList.forEach((ngSelectComponent: any) => {
      if (ngSelectComponent.element.id === 'select-' + propertyId) {
        ngSelectComponent.handleClearClick();
      }
    });
  }

  addToDropDown(importFile: Manifest, property: any, field: any): void {
    const name = property.type
      ? importFile.displayNames[property.type][field.entityProp]
      : importFile.displayNames[property.id][field.entityProp];
    importFile.dropdownDisplay[property.id] = [
      ...importFile.dropdownDisplay[property.id],
      { id: field.entityProp, name: name },
    ];
  }

  resizeMe(event: any, fieldName: any): void {
    const ibox = $(event.currentTarget).closest('div.ibox');
    const content = ibox.find('div.ibox-content');
    content.slideToggle(200);
    const dropdownName = fieldName.id ? fieldName.id : fieldName;
    this.chevronList[dropdownName] === undefined ||
    !this.chevronList[dropdownName]
      ? (this.chevronList[dropdownName] = true)
      : (this.chevronList[dropdownName] = false);
    ibox.toggleClass('').toggleClass('border-bottom');
    setTimeout(() => {
      ibox.trigger('resize');
      ibox.find('[id^=map-]').trigger('resize');
    }, 50);
  }

  formatDisplayNames(importFile: Manifest): void {
    let fieldMappings = importFile.fieldMappingDefinition;
    importFile.displayNames = {};
    Object.keys(fieldMappings).forEach((mapping) => {
      const mappingId = fieldMappings[mapping].id;
      importFile.displayNames = { ...importFile.displayNames, [mappingId]: {} };
      fieldMappings[mapping].fields.forEach((field) => {
        importFile.displayNames[mappingId] = {
          ...importFile.displayNames[mappingId],
          [field.id]: field.name,
        };
      });
      if (fieldMappings[mapping] && fieldMappings[mapping].complexTypes) {
        fieldMappings[mapping].complexTypes.forEach((complexType) => {
          Object.keys(complexType.anyOf).forEach((mapping) => {
            const mappingId = complexType.anyOf[mapping].id;
            importFile.displayNames = {
              ...importFile.displayNames,
              [mappingId]: {},
            };
            complexType.anyOf[mapping].fields.forEach((field) => {
              importFile.displayNames[mappingId] = {
                ...importFile.displayNames[mappingId],
                [field.id]: field.name,
              };
            });
          });
        });
      }
    });
  }

  formatImportFileName(importType: any): string {
    let name;
    switch (importType) {
      case 'ELIGIBILITY':
        name = 'Eligibility';
        break;
      case 'TRANSACTION':
        name = 'Transaction';
        break;
      case 'ORGANIZATION':
        name = 'Organization';
        break;
      default:
        name = 'Multiple';
        break;
    }

    return name;
  }

  selectField(importFile: Manifest, field: any, property: any): void {
    importFile.selectedField[property.id] = field;
  }

  addComplexFields(importFile: Manifest, property, i, field): void {
    if (
      importFile.openFieldChevronList.fieldPropertyIndexes[property.id] === i
    ) {
      importFile.openFieldChevronList.fieldPropertyIndexes[property.id] = null;
      importFile.selectedEditField[property.id] = null;
    } else {
      importFile.openFieldChevronList.fieldPropertyIndexes[property.id] = i;
      importFile.selectedEditField[property.id] = {
        entityName: field.entityName,
        entityProp: field.entityProp,
      };
    }
  }

  selectComplexType(importFile: Manifest, field: any, property: any): void {
    importFile.selectedComplexType[property.id] = field;
  }

  selectType(importFile: Manifest, field: any, property: any): void {
    importFile.selectedType[property.id] = field;
    importFile.selectedComplexType[property.id] = null;
    // TODO Check if this is limited to single import file
    this.ngSelectComponentList.forEach((ngSelectComponent: any) => {
      if (
        ngSelectComponent.element.id ===
        'selectComplexField-' + property.id
      ) {
        ngSelectComponent.handleClearClick();
      }
    });
  }

  addComplexType(
    importFile: Manifest,
    complexType: any,
    selectedType: any,
    property: any
  ): void {
    let id;
    let newId;
    if (!importFile.fieldMappings[property.id]) {
      importFile.fieldMappings[property.id] = [];
    }
    importFile.fieldMappings[property.id].forEach((ct) => {
      if (ct.type && ct.type === selectedType.id) {
        id = ct.id;
      }
    });
    if (id) {
      newId = Number(id.replace(/\D/g, '')) + 1;
    } else {
      newId = 1;
    }

    importFile.fieldMappings[property.id].push({
      id: selectedType.id + newId,
      name: selectedType.name,
      type: selectedType.id,
      entityProp: complexType.id,
      fieldMappings: [],
    });
    importFile.dropdownDisplay[selectedType.id + newId] = [];
    selectedType.fields.forEach((element) => {
      importFile.dropdownDisplay[selectedType.id + newId].push(element);
    });

    this.ngSelectComponentList.forEach((ngSelectComponent: any) => {
      if (ngSelectComponent.element.id === 'selectType-' + property.id) {
        ngSelectComponent.handleClearClick();
      }
    });

    this.toastr.success(`New ${selectedType.name} ${complexType.name} added`);
  }

  removeComplexType(importFile: Manifest, property: any, index: number): void {
    importFile.fieldMappings[property.id].splice(index, 1);
  }

  /* Field Mapping methods */

  editFieldMapping(
    importFile: Manifest,
    property: any,
    index: any,
    field: any,
    childField?: any
  ): void {
    const propertyId = childField ? field.id : property.id;
    const fieldToEdit = childField ? childField : field;
    if (
      importFile.openFieldChevronList.fieldPropertyIndexes[propertyId] === index
    ) {
      importFile.openFieldChevronList.fieldPropertyIndexes[propertyId] = null;
      importFile.selectedEditField[propertyId] = null;
    } else {
      importFile.openFieldChevronList.fieldPropertyIndexes[propertyId] = index;
      importFile.selectedEditField[propertyId] = {
        entityName: fieldToEdit.entityName,
        entityProp: fieldToEdit.entityProp,
      };
    }
  }

  handleFieldPropertyEvent(
    importFile: Manifest,
    data: any,
    property: any,
    complexField?: any,
    parentIndex?: any
  ): void {
    this.unsavedChanges = true;

    switch (data.type) {
      case 'add':
        this.addFieldMapping(importFile, property, data.value, complexField);
        break;
      case 'save':
        this.saveFieldMapping(
          importFile,
          property,
          data.index,
          data.value,
          complexField,
          parentIndex
        );
        break;
      default:
        this.resetFieldMapping(importFile, property);
        break;
    }
  }

  addFieldMapping(
    importFile: Manifest,
    property: any,
    fieldProperty: any,
    complexField?: any
  ): void {
    this.isFieldIndexUsed(importFile, fieldProperty.fieldIndex);
    const id = complexField
      ? importFile.selectedComplexType[property.id].id
      : importFile.selectedField[property.id].id;
    const selectedFieldModel = {
      ...fieldProperty,
      ...{
        entityName: fieldProperty.id,
        entityProp: id,
      },
    };
    if (complexField) {
      importFile.fieldMappings[property.id].forEach((ct) => {
        if (ct.id && ct.id === complexField) {
          if (!ct.fieldMappings) {
            ct.fieldMappings = [];
          }

          ct.fieldMappings.push(selectedFieldModel);
          this.removeFromDropdown(importFile, complexField, selectedFieldModel);
          this.toastr.success('Field Mapping added');
        }
      });
    } else {
      if (!importFile.fieldMappings[property.id]) {
        importFile.fieldMappings[property.id] = [];
      }
      importFile.fieldMappings[property.id].push(selectedFieldModel);
      this.removeFromDropdown(importFile, property.id, selectedFieldModel);
      this.toastr.success('Field Mapping added');
    }
  }

  saveFieldMapping(
    importFile: Manifest,
    property: any,
    index: number,
    fieldProperty: any,
    complexField?: any,
    parentIndex?: any
  ): void {
    const id = complexField ? complexField.id : property.id;
    if (complexField) {
      if (
        importFile.fieldMappings[property.id][parentIndex].fieldMappings[index]
          .fieldIndex !== fieldProperty.fieldIndex
      ) {
        this.isFieldIndexUsed(importFile, fieldProperty.fieldIndex);
      }
      importFile.fieldMappings[property.id][parentIndex].fieldMappings[index] =
        {
          ...fieldProperty,
          entityName: importFile.selectedEditField[id].entityName,
          entityProp: importFile.selectedEditField[id].entityProp,
        };
    } else {
      if (
        importFile.fieldMappings[property.id][index].fieldIndex !==
        fieldProperty.fieldIndex
      ) {
        this.isFieldIndexUsed(importFile, fieldProperty.fieldIndex);
      }
      importFile.fieldMappings[property.id][index] = {
        ...fieldProperty,
        entityName: importFile.selectedEditField[id].entityName,
        entityProp: importFile.selectedEditField[id].entityProp,
      };
    }

    importFile.openFieldChevronList.fieldPropertyIndexes[id] = null;
    importFile.selectedEditField[id] = null;
    this.toastr.success('Field Mapping saved');
  }

  resetFieldMapping(importFile: Manifest, property: any): void {
    importFile.openFieldChevronList.fieldPropertyIndexes[property.id] = null;
    importFile.selectedEditField[property.id] = null;
  }

  removeFieldMapping(
    importFile: Manifest,
    property: any,
    index: number,
    fieldProperty: any,
    childField: any
  ): void {
    if (!fieldProperty.type) {
      this.addToDropDown(importFile, property, fieldProperty);
    }
    if (childField) {
      this.addToDropDown(importFile, fieldProperty, childField);
      importFile.fieldMappings[property.id]
        .find((obj) => obj.id === fieldProperty.id)
        .fieldMappings.splice(index, 1);
    } else {
      importFile.fieldMappings[property.id].splice(index, 1);
    }
    this.toastr.success('Field Mapping removed');
  }

  /* Member Instrument methods */

  handleMemberInstrumentEvent(importFile: Manifest, data: any): void {
    this.unsavedChanges = true;

    switch (data.type) {
      case 'add':
        this.addMemberInstrument(importFile, data.value);
        break;
      case 'save':
        this.saveMemberInstrument(importFile, data.index, data.value);
        break;
      default:
        this.removeMemberInstrument(importFile, data.index);
        break;
    }
  }

  editMemberInstrument(importFile: Manifest, index: number): void {
    if (importFile.openFieldChevronList.memberInstrumentIndex === index) {
      importFile.openFieldChevronList.memberInstrumentIndex = null;
    } else {
      importFile.openFieldChevronList.memberInstrumentIndex = index;
    }
  }

  addMemberInstrument(
    importFile: Manifest,
    memberInstrument: MemberInstrument
  ): void {
    this.isFieldIndexUsed(importFile, memberInstrument.fieldIndex);
    if (!importFile.memberInstrument) {
      importFile.memberInstrument = [];
    }
    importFile.memberInstrument.push(memberInstrument);
    this.toastr.success('Instrument added');
  }

  saveMemberInstrument(
    importFile: Manifest,
    index: number,
    memberInstrument: MemberInstrument
  ): void {
    if (
      importFile.memberInstrument[index].fieldIndex !==
      memberInstrument.fieldIndex
    ) {
      this.isFieldIndexUsed(importFile, memberInstrument.fieldIndex);
    }
    importFile.memberInstrument[index] = memberInstrument;
    this.toastr.success('Instrument saved');
  }

  removeMemberInstrument(importFile: Manifest, index: number): void {
    importFile.memberInstrument.splice(index, 1);
    this.toastr.success('Instrument removed');
  }

  /* Custom Attribute methods */

  handleCustomAttributeEvent(importFile: Manifest, data: any): void {
    this.unsavedChanges = true;

    switch (data.type) {
      case 'add':
        this.addCustomAttribute(importFile, data.value);
        break;
      case 'save':
        this.saveCustomAttribute(importFile, data.index, data.value);
        break;
      default:
        this.removeCustomAttribute(importFile, data.index);
        break;
    }
  }

  editCustomAttribute(importFile: Manifest, index: number): void {
    if (importFile.openFieldChevronList.customAttributeIndex === index) {
      importFile.openFieldChevronList.customAttributeIndex = null;
    } else {
      importFile.openFieldChevronList.customAttributeIndex = index;
    }
  }

  addCustomAttribute(
    importFile: Manifest,
    customAttribute: CustomAttribute
  ): void {
    this.isFieldIndexUsed(importFile, customAttribute.fieldIndex);
    if (!importFile.customAttribute) {
      importFile.customAttribute = [];
    }
    importFile.customAttribute.push(customAttribute);
    this.toastr.success('Attribute added');
  }

  saveCustomAttribute(
    importFile: Manifest,
    index: number,
    customAttribute: CustomAttribute
  ): void {
    if (
      importFile.customAttribute[index].fieldIndex !==
      customAttribute.fieldIndex
    ) {
      this.isFieldIndexUsed(importFile, customAttribute.fieldIndex);
    }
    importFile.customAttribute[index] = customAttribute;
    this.toastr.success('Attribute saved');
  }

  removeCustomAttribute(importFile: Manifest, index: number): void {
    importFile.customAttribute.splice(index, 1);
    this.toastr.success('Attribute removed');
  }

  /* Segment Mapping methods */

  handleSegmentMappingEvent(importFile: Manifest, data: any): void {
    this.unsavedChanges = true;

    switch (data.type) {
      case 'add':
        this.addSegmentMapping(importFile, data.value);
        break;
      case 'save':
        this.saveSegmentMapping(importFile, data.index, data.value);
        break;
      default:
        this.removeSegmentMapping(importFile, data.index);
        break;
    }
  }

  editSegmentMapping(importFile: Manifest, index: number): void {
    if (importFile.openFieldChevronList.segmentMappingIndex === index) {
      importFile.openFieldChevronList.segmentMappingIndex = null;
    } else {
      importFile.openFieldChevronList.segmentMappingIndex = index;
    }
  }

  addSegmentMapping(
    importFile: Manifest,
    segmentMapping: SegmentMapping
  ): void {
    this.isFieldIndexUsed(importFile, segmentMapping.fieldIndex);
    if (!importFile.segmentMapping) {
      importFile.segmentMapping = [];
    }
    importFile.segmentMapping.push(segmentMapping);
    this.toastr.success('Segment Mapping added');
  }

  saveSegmentMapping(
    importFile: Manifest,
    index: number,
    segmentMapping: SegmentMapping
  ): void {
    if (
      importFile.segmentMapping[index].fieldIndex !== segmentMapping.fieldIndex
    ) {
      this.isFieldIndexUsed(importFile, segmentMapping.fieldIndex);
    }
    importFile.segmentMapping[index] = segmentMapping;
    this.toastr.success('Segment Mapping saved');
  }

  removeSegmentMapping(importFile: Manifest, index: number): void {
    importFile.segmentMapping.splice(index, 1);
    this.toastr.success('Segment Mapping removed');
  }

  /* Member Relationship methods */

  handleMemberRelationshipEvent(importFile: Manifest, data: any): void {
    this.unsavedChanges = true;

    switch (data.type) {
      case 'add':
        this.addMemberRelationship(importFile, data.value);
        break;
      case 'save':
        this.saveMemberRelationship(importFile, data.index, data.value);
        break;
      default:
        this.removeMemberRelationship(importFile, data.index);
        break;
    }
  }

  editMemberRelationship(importFile: Manifest, index: number): void {
    if (importFile.openFieldChevronList.memberRelationshipIndex === index) {
      importFile.openFieldChevronList.memberRelationshipIndex = null;
    } else {
      importFile.openFieldChevronList.memberRelationshipIndex = index;
    }
  }

  addMemberRelationship(
    importFile: Manifest,
    memberRelationship: MemberRelationship
  ): void {
    this.isFieldIndexUsed(importFile, memberRelationship.fieldIndex);
    if (!importFile.memberRelationship) {
      importFile.memberRelationship = [];
    }
    importFile.memberRelationship.push(memberRelationship);
    this.toastr.success('Relationship added');
  }

  saveMemberRelationship(
    importFile: Manifest,
    index: number,
    memberRelationship: MemberRelationship
  ): void {
    if (
      importFile.memberRelationship[index].fieldIndex !==
      memberRelationship.fieldIndex
    ) {
      this.isFieldIndexUsed(importFile, memberRelationship.fieldIndex);
    }
    importFile.memberRelationship[index] = memberRelationship;
    this.toastr.success('Relationship saved');
  }

  removeMemberRelationship(importFile: Manifest, index: number): void {
    importFile.memberRelationship.splice(index, 1);
    this.toastr.success('Relationship removed');
  }

  createJSON(): void {
    this.unsavedChanges = false;
    this.updateJSON();

    this.integrationsService
      .updateIntegrationManifest(
        this.integrationId,
        { importFile: this.packagedModel },
        {
          format: 'json',
        }
      )
      .subscribe(
        (response) => {
          this.toastr.success('Manifest updated');
        },
        (error) => {
          this.responseHelperService.error(this, error.errorMsg);
        }
      );
  }

  cancel() {
    this.location.back();
  }

  updateJSON(): void {
    this.packagedModel = [];
    this.model.forEach((importFile: Manifest) => {
      this.packagedModel.push({
        importType: importFile.importType,
        fieldMappings: importFile.fieldMappings,
        memberInstrument: importFile.memberInstrument,
        customAttribute: importFile.customAttribute,
        segmentMapping: importFile.segmentMapping,
        memberRelationship: importFile.memberRelationship,
      });
    });
    this.rawJSON = JSON.stringify(this.packagedModel, null, '\t');
  }

  addImportFile() {
    this.unsavedChanges = true;

    const importFile = {
      importType: this.addImportFileType,
      fieldMappings: {},
      memberInstrument: [],
      customAttribute: [],
      segmentMapping: [],
      memberRelationship: [],
    };

    if (this.addImportFileType) {
      this.setData(importFile);

      this.model.push(importFile);

      this.addImportFileType = null;
    }
  }

  assignMemberMappings(importType: string): any {
    let mappings = [
      {
        id: 'member',
        name: 'Member Data',
        fields: [
          {
            id: 'firstName',
            name: 'First name',
          },
          {
            id: 'lastName',
            name: 'Last name',
          },
          {
            id: 'middleInitial',
            name: 'Middle Initial',
          },
          {
            id: 'suffix',
            name: 'Suffix',
          },
          {
            id: 'gender',
            name: 'Gender',
          },
          {
            id: 'homePhone',
            name: 'Home Phone',
          },
          {
            id: 'businessPhone',
            name: 'Business Phone',
          },
          {
            id: 'mobilePhone',
            name: 'Mobile Phone',
          },
          {
            id: 'dateOfBirth',
            name: 'Date Of Birth',
          },
          {
            id: 'username',
            name: 'Username',
          },
          {
            id: 'password',
            name: 'Password',
          },
          {
            id: 'memberURL',
            name: 'Member URL',
          },
        ],
      },
      {
        id: 'memberAddress',
        name: 'Member Addresses',
        fields: [
          {
            id: 'addressType',
            name: 'Address Type',
          },
          {
            id: 'primary',
            name: 'Primary',
          },
          {
            id: 'address',
            name: 'Address',
          },
          {
            id: 'address2',
            name: 'Address2',
          },
          {
            id: 'city',
            name: 'City',
          },
          {
            id: 'state',
            name: 'State',
          },
          {
            id: 'zip',
            name: 'Zip',
          },
          {
            id: 'country',
            name: 'Country',
          },
        ],
      },
      {
        id: 'memberEmailAddress',
        name: 'Member Email Addresses',
        fields: [
          {
            id: 'emailAddress',
            name: 'Email Address',
          },
          {
            id: 'primary',
            name: 'Primary',
          },
        ],
      },
    ];

    if (importType === 'eligibility') {
      mappings[0].fields = mappings[0].fields.filter((field) => {
        return field.id != 'role';
      });
    }

    return mappings;
  }

  assignTransactionMappings(): any {
    return [
      {
        id: 'transaction',
        name: 'Activiy Data',
        fields: [
          {
            id: 'txnRefNum',
            name: 'Transaction Reference Number',
          },
          {
            id: 'originalTntRefNum',
            name: 'Original Tnt Ref Num',
          },
          {
            id: 'amount',
            name: 'Amount',
          },
          {
            id: 'locationCode',
            name: 'Location Code',
          },
          {
            id: 'activityCode',
            name: 'Activity Code',
          },
          {
            id: 'status',
            name: 'Status',
          },
          {
            id: 'behaviorHierarchySource',
            name: 'Behavior Hierarchy Source Id',
          },
          {
            id: 'txnDateTime',
            name: 'Transaction Date Time',
          },
          {
            id: 'locationHierarchySource',
            name: 'Location Hierarchy Source Id',
          },
        ],
      },
      {
        id: 'txnInstrument',
        name: 'Transaction Instrument',
        fields: [
          {
            id: 'instrumentType',
            name: 'Instrument Type',
          },
          {
            id: 'instrumentNumber',
            name: 'Instrument Number',
          },
        ],
      },
      {
        id: 'txnProduct',
        name: 'Transaction Products',
        fields: [
          {
            id: 'quantity',
            name: 'Quantity',
          },
          {
            id: 'amount',
            name: 'Amount',
          },
          {
            id: 'product',
            name: 'Product',
          },
        ],
      },
    ];
  }

  assignOrganizationMappings(): any {
    return [
      {
        id: 'organization',
        name: 'Organization Data',
        fields: [
          {
            id: 'organizationName',
            name: 'Organization name',
          },
          {
            id: 'description',
            name: 'Description',
          },
          {
            id: 'notes',
            name: 'Notes',
          },
          {
            id: 'createLogin',
            name: 'Create Login',
          },
          {
            id: 'memberCredential',
            name: 'Member Credential',
          },
          {
            id: 'username',
            name: 'Username',
          },
          {
            id: 'password',
            name: 'Password',
          },
          {
            id: 'yearStart',
            name: 'Year Start',
          },
          {
            id: 'sponsor',
            name: 'Sponsor',
          },
        ],
      },
      {
        id: 'organizationAddress',
        name: 'Organization Address',
        fields: [
          {
            id: 'primary',
            name: 'Primary',
          },
          {
            id: 'address',
            name: 'Address',
          },
          {
            id: 'address2',
            name: 'Address2',
          },
          {
            id: 'city',
            name: 'City',
          },
          {
            id: 'state',
            name: 'State',
          },
          {
            id: 'zip',
            name: 'Zip',
          },
          {
            id: 'country',
            name: 'Country',
          },
        ],
      },
    ];
  }

  listDropHandler(event: any) {
    const { currentIndex, previousIndex } = event;
    const currentElement = { ...this.model[currentIndex] };
    this.model[currentIndex] = this.model[previousIndex];
    this.model[previousIndex] = currentElement;
  }

  onDragStart(importFile: Manifest) {
    importFile.selected = true;
  }

  onDragEnd(importFile: Manifest) {
    importFile.selected = false;
  }

  listToggleOpen(importFile: Manifest, index: number) {
    importFile.expandedLookup = !importFile.expandedLookup;
    this.ngbAccordionList.get(index).toggle('import_file_panel');
  }

  deleteImportFile(importFile: Manifest, index: number) {
    const instance = this.modalHelper.open(ConfirmDialogComponent, {
      size: 'sm',
    });
    instance.componentInstance.text =
      'Delete ' + importFile.importType + ' import file';
    instance.componentInstance.header = 'Delete Import File?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.unsavedChanges = true;

      this.model.splice(index, 1);
      this.responseHelperService.success(
        'Import file successfully removed',
        true
      );
    });
  }
}
