<div class="wrapper wrapper-content">
  <div class="row pb-15">
    <div class="col-lg-4">
      <ng-select
        [items]="importTypes"
        bindLabel="name"
        bindValue="key"
        [(ngModel)]="addImportFileType"
        placeholder="Add manifest type"
      ></ng-select>
    </div>
    <div class="col-lg-2">
      <button
        (click)="addImportFile()"
        class="btn btn-outline-primary"
        type="button"
        title="Add Import File"
      >
        <fa-icon [icon]="faPlus"></fa-icon>
      </button>
    </div>
    <div class="col-lg-6">
      <button
        id="create-json"
        class="btn btn-save float-right"
        type="button"
        (click)="createJSON()"
      >
        SAVE
      </button>
      <button
        (click)="cancel()"
        class="btn btn-pop-cancel float-right"
        type="reset"
        title="Cancel"
      >
        CANCEL
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="row import-list-header">
        <div class="offset-lg-1 col-lg-9">
          <h4>Name</h4>
        </div>
        <div class="col-lg-2">
          <h4>Actions</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <ul
            class="dndList import-list"
            cdkDropListGroup
            cdkDropList
            (cdkDropListDropped)="listDropHandler($event)"
          >
            <li
              *ngFor="let importFile of model; let index = index"
              cdkDrag
              [cdkDragDisabled]="importFile.expandedLookup"
              (cdkDragStarted)="onDragStart(importFile)"
              (cdkDragEnded)="onDragEnd(importFile)"
            >
              <span>
                <div
                  ngbAccordion
                  #accMain="ngbAccordion"
                  class="import-list-element"
                  [class.selected-import-file]="importFile.selected"
                >
                  <div ngbAccordionItem="import_file_panel">
                    <div ngbAccordionHeader>
                      <div class="row p-2 d-flex align-items-center">
                        <div
                          class="col-md-1 import-list-icon cursor-pointer"
                          (click)="listToggleOpen(importFile, index)"
                        >
                          <span *ngIf="!importFile.expandedLookup">
                            <fa-icon
                              [icon]="faChevronRight"
                              transform="grow-8"
                              class="primary-icon"
                            ></fa-icon>
                          </span>
                          <span *ngIf="importFile.expandedLookup">
                            <fa-icon
                              [icon]="faChevronDown"
                              transform="grow-8"
                              class="primary-icon"
                            ></fa-icon>
                          </span>
                        </div>
                        <div class="col-md-9">
                          <h4 class="m-0">
                            {{ formatImportFileName(importFile.importType) }}
                          </h4>
                        </div>
                        <div class="col-md-1">
                          <button
                            (click)="deleteImportFile(importFile, index)"
                            class="btn btn-outline-primary float-right"
                            type="button"
                            title="Delete Import File"
                          >
                            <fa-icon [icon]="faTrash"></fa-icon>
                          </button>
                        </div>
                        <div class="col-md-1">
                          <fa-icon
                            [icon]="faArrowsAltV"
                            transform="grow-8"
                            class="float-right import-list-icon primary-icon"
                          ></fa-icon>
                        </div>
                      </div>
                    </div>
                    <div ngbAccordionCollapse>
                      <div
                        class="row"
                        *ngFor="
                          let property of importFile.fieldMappingDefinition
                        "
                      >
                        <div class="col-lg-12">
                          <div class="ibox collapsed border-bottom">
                            <div
                              class="ibox-title collapse-link import-mapping-header"
                              (click)="resizeMe($event, property)"
                            >
                              <h5>{{ property.name }}</h5>
                              <div class="ibox-tools">
                                <a>
                                  <fa-icon
                                    [icon]="
                                      chevronList[property.id]
                                        ? faChevronUp
                                        : faChevronDown
                                    "
                                  ></fa-icon>
                                </a>
                              </div>
                            </div>
                            <form method="get" class="form-horizontal">
                              <div class="ibox-content">
                                <div
                                  ngbAccordion
                                  #acc="ngbAccordion"
                                  closeOthers="true"
                                >
                                  <div
                                    ngbAccordionItem
                                    *ngFor="
                                      let field of importFile.fieldMappings[
                                        property.id
                                      ];
                                      let i = index
                                    "
                                    id="static-{{ i }}"
                                  >
                                    <div ngbAccordionHeader>
                                      <div
                                        class="d-flex allign-items-center justify-content-between"
                                      >
                                        <h5
                                          *ngIf="!field.type"
                                          class="ml-2 my-0 d-flex align-items-center"
                                        >
                                          {{
                                            importFile.displayNames[
                                              property.id
                                            ][field.entityProp]
                                          }}
                                          {{
                                            field.fieldName
                                              ? "- " + field.fieldName
                                              : ""
                                          }}
                                        </h5>
                                        <h5
                                          *ngIf="field.type"
                                          class="ml-2 my-0 d-flex align-items-center"
                                        >
                                          {{ field.name }}
                                          {{ "- " + field.id }}
                                        </h5>
                                        <div>
                                          <button
                                            ngbAccordionToggle
                                            class="btn btn-dark-blue"
                                            (click)="
                                              field.type
                                                ? addComplexFields(
                                                    importFile,
                                                    property,
                                                    i,
                                                    field
                                                  )
                                                : editFieldMapping(
                                                    importFile,
                                                    property,
                                                    i,
                                                    field
                                                  )
                                            "
                                            title="View/Edit"
                                          >
                                            <fa-icon
                                              [icon]="
                                                i ===
                                                importFile.openFieldChevronList
                                                  .fieldPropertyIndexes[
                                                  property.id
                                                ]
                                                  ? faChevronUp
                                                  : faChevronDown
                                              "
                                            ></fa-icon>
                                          </button>
                                          <button
                                            class="btn btn-dark-blue"
                                            (click)="
                                              removeFieldMapping(
                                                importFile,
                                                property,
                                                i,
                                                field
                                              )
                                            "
                                            title="Remove"
                                          >
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div ngbAccordionCollapse>
                                      <div *ngIf="!field.type">
                                        <h2>
                                          {{ field.fieldName }}
                                        </h2>
                                        <app-manifest-field-property-form
                                          [index]="i"
                                          [fieldProperty]="
                                            importFile.fieldMappings[
                                              property.id
                                            ][i]
                                          "
                                          (fieldPropertyEvent)="
                                            handleFieldPropertyEvent(
                                              importFile,
                                              $event,
                                              property
                                            );
                                            $event.type !== 'add'
                                              ? acc.toggle('static-' + i)
                                              : ''
                                          "
                                        ></app-manifest-field-property-form>
                                      </div>
                                      <!-- Complex Type Field Mappings -->
                                      <div>
                                        <div
                                          id="selected-field"
                                          class="form-group"
                                          *ngIf="field.id"
                                        >
                                          <div
                                            ngbAccordion
                                            #accordion="ngbAccordion"
                                            closeOthers="true"
                                          >
                                            <div
                                              *ngFor="
                                                let childField of field.fieldMappings;
                                                let childIndex = index
                                              "
                                              id="static-{{ childIndex }}"
                                            >
                                              <div ngbAccordionHeader>
                                                <div
                                                  class="d-flex allign-items-center justify-content-between"
                                                >
                                                  <h5
                                                    class="ml-2 my-0 d-flex align-items-center"
                                                  >
                                                    {{
                                                      importFile.displayNames[
                                                        field.type
                                                      ][childField.entityProp]
                                                    }}
                                                    {{
                                                      childField.fieldName
                                                        ? "- " +
                                                          childField.fieldName
                                                        : ""
                                                    }}
                                                  </h5>
                                                  <div>
                                                    <button
                                                      ngbAccordionToggle
                                                      class="btn btn-dark-blue"
                                                      (click)="
                                                        editFieldMapping(
                                                          importFile,
                                                          property,
                                                          childIndex,
                                                          field,
                                                          childField
                                                        )
                                                      "
                                                      title="View/Edit"
                                                    >
                                                      <fa-icon
                                                        [icon]="
                                                          childIndex ===
                                                          importFile
                                                            .openFieldChevronList
                                                            .fieldPropertyIndexes[
                                                            field.id
                                                          ]
                                                            ? faChevronUp
                                                            : faChevronDown
                                                        "
                                                      ></fa-icon>
                                                    </button>
                                                    <button
                                                      class="btn btn-dark-blue"
                                                      (click)="
                                                        removeFieldMapping(
                                                          importFile,
                                                          property,
                                                          childIndex,
                                                          field,
                                                          childField
                                                        )
                                                      "
                                                      title="Remove"
                                                    >
                                                      <fa-icon
                                                        [icon]="faTrash"
                                                      ></fa-icon>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                              <div ngbAccordionCollapse>
                                                <h2>
                                                  {{ field.fieldName }}
                                                </h2>
                                                <app-manifest-field-property-form
                                                  [index]="childIndex"
                                                  [fieldProperty]="
                                                    importFile.fieldMappings[
                                                      property.id
                                                    ][i].fieldMappings[
                                                      childIndex
                                                    ]
                                                  "
                                                  (fieldPropertyEvent)="
                                                    handleFieldPropertyEvent(
                                                      importFile,
                                                      $event,
                                                      property,
                                                      field,
                                                      i
                                                    );
                                                    $event.type !== 'add'
                                                      ? accordion.toggle(
                                                          'static-' + childIndex
                                                        )
                                                      : ''
                                                  "
                                                ></app-manifest-field-property-form>
                                              </div>
                                            </div>
                                          </div>
                                          <label
                                            class="col-sm-4 control-label align-left"
                                            >Fields</label
                                          >
                                          <div
                                            class="col-sm-4"
                                            style="min-height: 200px !important"
                                          >
                                            <ng-select
                                              id="select-{{ field.id }}"
                                              [items]="
                                                importFile.dropdownDisplay[
                                                  field.id
                                                ]
                                              "
                                              bindLabel="name"
                                              (change)="
                                                selectComplexType(
                                                  importFile,
                                                  $event,
                                                  property
                                                )
                                              "
                                              bindValue="id"
                                              [clearable]="true"
                                              #typeDropdown
                                            >
                                            </ng-select>
                                          </div>
                                        </div>
                                        <app-manifest-field-property-form
                                          *ngIf="
                                            importFile.selectedComplexType[
                                              property.id
                                            ]
                                          "
                                          [index]="null"
                                          (fieldPropertyEvent)="
                                            handleFieldPropertyEvent(
                                              importFile,
                                              $event,
                                              property,
                                              field.id
                                            )
                                          "
                                        >
                                        </app-manifest-field-property-form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div id="selected-field" class="form-group">
                                  <label
                                    class="col-sm-offset-1 col-sm-2 control-label align-left"
                                    >Field</label
                                  >
                                  <div class="col-sm-4">
                                    <ng-select
                                      id="select-{{ property.id }}"
                                      [items]="
                                        importFile.dropdownDisplay[property.id]
                                      "
                                      (change)="
                                        selectField(
                                          importFile,
                                          $event,
                                          property
                                        )
                                      "
                                      bindLabel="name"
                                      bindValue="id"
                                      [clearable]="true"
                                    ></ng-select>
                                  </div>
                                </div>
                                <app-manifest-field-property-form
                                  *ngIf="importFile.selectedField[property.id]"
                                  [index]="null"
                                  (fieldPropertyEvent)="
                                    handleFieldPropertyEvent(
                                      importFile,
                                      $event,
                                      property
                                    )
                                  "
                                ></app-manifest-field-property-form>

                                <!-- Complex Types -->
                                <div
                                  *ngIf="
                                    property.complexTypes &&
                                    property.complexTypes.length > 0
                                  "
                                >
                                  <div
                                    id="selected-field"
                                    class="form-group"
                                    *ngFor="
                                      let complexType of property.complexTypes;
                                      let i = index
                                    "
                                  >
                                    <hr />
                                    <h4>{{ complexType.name }}</h4>
                                    <label
                                      class="col-sm-offset-1 col-sm-2 control-label align-left"
                                    ></label>
                                    <div class="col-sm-8 row">
                                      <div class="col-sm-7">
                                        <ng-select
                                          id="selectType-{{ property.id }}"
                                          [items]="complexType.anyOf"
                                          (change)="
                                            selectType(
                                              importFile,
                                              $event,
                                              property
                                            )
                                          "
                                          bindLabel="name"
                                          bindValue="id"
                                          [clearable]="true"
                                        ></ng-select>
                                      </div>
                                      <div class="col-sm-5">
                                        <button
                                          class="btn btn-dark-blue"
                                          (click)="
                                            addComplexType(
                                              importFile,
                                              complexType,
                                              importFile.selectedType[
                                                property.id
                                              ],
                                              property
                                            )
                                          "
                                          [disabled]="
                                            !importFile.selectedType[
                                              property.id
                                            ]
                                          "
                                          title="Add Contact Method"
                                        >
                                          <fa-icon [icon]="faPlus"></fa-icon>
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      *ngIf="
                                        importFile.fieldMappings[property.id] &&
                                        importFile.fieldMappings[property.id][
                                          complexType.id
                                        ] &&
                                        importFile.fieldMappings[property.id][
                                          complexType.id
                                        ].length > 0
                                      "
                                    >
                                      <form
                                        method="get"
                                        class="form-horizontal"
                                      >
                                        <div class="ibox-content">
                                          <div
                                            ngbAccordion
                                            #acc="ngbAccordion"
                                            [closeOthers]="true"
                                          >
                                            <div
                                              ngbAccordionItem
                                              *ngFor="
                                                let field of importFile
                                                  .fieldMappings[property.id][
                                                  complexType.id
                                                ];
                                                let i = index
                                              "
                                              id="static-{{ i }}"
                                            >
                                              <div ngbAccordionHeader>
                                                <div class="row">
                                                  <h5>
                                                    {{ field.id }}
                                                  </h5>
                                                  <button
                                                    class="btn btn-dark-blue"
                                                    (click)="
                                                      addComplexFields(
                                                        importFile,
                                                        property,
                                                        i,
                                                        field
                                                      )
                                                    "
                                                    title="View/Edit"
                                                  >
                                                    <fa-icon
                                                      [icon]="
                                                        i ===
                                                        importFile
                                                          .openFieldChevronList
                                                          .fieldPropertyIndexes
                                                          ? faChevronUp
                                                          : faChevronDown
                                                      "
                                                    ></fa-icon>
                                                  </button>
                                                  <button
                                                    class="btn btn-dark-blue"
                                                    (click)="
                                                      removeComplexType(
                                                        importFile,
                                                        property,
                                                        i
                                                      )
                                                    "
                                                    title="Remove"
                                                  >
                                                    <fa-icon
                                                      [icon]="faTrash"
                                                    ></fa-icon>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row"
                        [hidden]="!(importFile.importType !== 'transaction')"
                      >
                        <div class="col-lg-12">
                          <div class="ibox collapsed border-bottom">
                            <div
                              class="ibox-title collapse-link import-mapping-header"
                              (click)="resizeMe($event, 'memberInstrument')"
                            >
                              <h5>Identifiers</h5>
                              <div class="ibox-tools">
                                <a>
                                  <fa-icon
                                    [icon]="
                                      chevronList['memberInstrument']
                                        ? faChevronUp
                                        : faChevronDown
                                    "
                                  ></fa-icon>
                                </a>
                              </div>
                            </div>
                            <form
                              method="get"
                              class="form-horizontal import-mapping-body"
                            >
                              <div class="ibox-content">
                                <div ngbAccordion [closeOthers]="true">
                                  <div
                                    ngbAccordionItem
                                    *ngFor="
                                      let field of importFile.memberInstrument;
                                      let i = index
                                    "
                                    id="static-{{ i }}"
                                  >
                                    <div ngbAccordionHeader>
                                      <div
                                        class="d-flex allign-items-center justify-content-between"
                                      >
                                        <h5
                                          class="ml-2 my-0 d-flex align-items-center"
                                        >
                                          Instrument
                                          {{
                                            field.InstrumentTypeField
                                              ? "- " + field.InstrumentTypeField
                                              : ""
                                          }}
                                        </h5>
                                        <div>
                                          <button
                                            ngbAccordionToggle
                                            class="btn btn-dark-blue"
                                            (click)="
                                              editMemberInstrument(
                                                importFile,
                                                i
                                              )
                                            "
                                            title="View/Edit"
                                          >
                                            <fa-icon
                                              [icon]="
                                                i ===
                                                importFile.openFieldChevronList
                                                  .memberInstrumentIndex
                                                  ? faChevronUp
                                                  : faChevronDown
                                              "
                                            ></fa-icon>
                                          </button>
                                          <button
                                            class="btn btn-dark-blue"
                                            (click)="
                                              removeMemberInstrument(
                                                importFile,
                                                i
                                              )
                                            "
                                            title="Remove"
                                          >
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div ngbAccordionCollapse>
                                      <h2>
                                        {{ field.instrumentTypeField }} -
                                        {{ field.instrumentTypeDefault }}
                                      </h2>
                                      <app-manifest-member-instrument-form
                                        [index]="i"
                                        [memberInstrument]="
                                          importFile.memberInstrument[i]
                                        "
                                        (memberInstrumentEvent)="
                                          handleMemberInstrumentEvent(
                                            importFile,
                                            $event
                                          )
                                        "
                                      ></app-manifest-member-instrument-form>
                                    </div>
                                  </div>
                                </div>
                                <h2>New Instrument</h2>
                                <app-manifest-member-instrument-form
                                  [index]="null"
                                  (memberInstrumentEvent)="
                                    handleMemberInstrumentEvent(
                                      importFile,
                                      $event
                                    )
                                  "
                                ></app-manifest-member-instrument-form>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="ibox collapsed border-bottom">
                            <div
                              class="ibox-title collapse-link import-mapping-header"
                              (click)="resizeMe($event, 'customAttribute')"
                            >
                              <h5>Attributes</h5>
                              <div class="ibox-tools">
                                <a>
                                  <fa-icon
                                    [icon]="
                                      chevronList['customAttribute']
                                        ? faChevronUp
                                        : faChevronDown
                                    "
                                  ></fa-icon>
                                </a>
                              </div>
                            </div>
                            <form
                              method="get"
                              class="form-horizontal import-mapping-body"
                            >
                              <div class="ibox-content">
                                <div
                                  ngbAccordion
                                  #acc="ngbAccordion"
                                  [closeOthers]="true"
                                >
                                  <div
                                    ngbAccordionItem
                                    *ngFor="
                                      let field of importFile.customAttribute;
                                      let i = index
                                    "
                                    id="static-{{ i }}"
                                  >
                                    <div ngbAccordionHeader>
                                      <div
                                        class="d-flex allign-items-center justify-content-between"
                                      >
                                        <h5
                                          class="ml-2 my-0 d-flex align-items-center"
                                        >
                                          Attribute
                                          {{
                                            importFile.customAttribute[i]
                                              .attrName
                                              ? "- " +
                                                importFile.customAttribute[i]
                                                  .attrName
                                              : field.AttrNameIndex
                                          }}
                                        </h5>
                                        <div>
                                          <button
                                            ngbAccordionToggle
                                            class="btn btn-dark-blue"
                                            (click)="
                                              editCustomAttribute(importFile, i)
                                            "
                                            title="View/Edit"
                                          >
                                            <fa-icon
                                              [icon]="
                                                i ===
                                                importFile.openFieldChevronList
                                                  .customAttributeIndex
                                                  ? faChevronUp
                                                  : faChevronDown
                                              "
                                            ></fa-icon>
                                          </button>
                                          <button
                                            class="btn btn-dark-blue"
                                            (click)="
                                              removeCustomAttribute(
                                                importFile,
                                                i
                                              )
                                            "
                                            title="Remove"
                                          >
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div ngbAccordionCollapse>
                                      <h2>
                                        {{ field.attrName }} -
                                        {{ field.attrNameIndex }}
                                      </h2>
                                      <app-manifest-custom-attribute-form
                                        [index]="i"
                                        [customAttribute]="
                                          importFile.customAttribute[i]
                                        "
                                        (customAttributeEvent)="
                                          handleCustomAttributeEvent(
                                            importFile,
                                            $event
                                          )
                                        "
                                      ></app-manifest-custom-attribute-form>
                                    </div>
                                  </div>
                                </div>
                                <h2>New Attribute</h2>
                                <app-manifest-custom-attribute-form
                                  [index]="null"
                                  (customAttributeEvent)="
                                    handleCustomAttributeEvent(
                                      importFile,
                                      $event
                                    )
                                  "
                                ></app-manifest-custom-attribute-form>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row"
                        [hidden]="!(importFile.importType !== 'transaction')"
                      >
                        <div class="col-lg-12">
                          <div class="ibox collapsed border-bottom">
                            <div
                              class="ibox-title collapse-link import-mapping-header"
                              (click)="resizeMe($event, 'segmentMapping')"
                            >
                              <h5>Segment Mappings</h5>
                              <div class="ibox-tools">
                                <a>
                                  <fa-icon
                                    [icon]="
                                      chevronList['segmentMapping']
                                        ? faChevronUp
                                        : faChevronDown
                                    "
                                  ></fa-icon>
                                </a>
                              </div>
                            </div>
                            <form
                              method="get"
                              class="form-horizontal import-mapping-body"
                            >
                              <div class="ibox-content">
                                <div
                                  ngbAccordion
                                  #acc="ngbAccordion"
                                  [closeOthers]="true"
                                >
                                  <div
                                    ngbAccordionItem
                                    *ngFor="
                                      let field of importFile.segmentMapping;
                                      let i = index
                                    "
                                    id="static-{{ i }}"
                                  >
                                    <div ngbAccordionHeader>
                                      <div
                                        class="d-flex allign-items-center justify-content-between"
                                      >
                                        <h5
                                          class="ml-2 my-0 d-flex align-items-center"
                                        >
                                          Segment Mapping
                                          {{
                                            field.fieldName
                                              ? "- " + field.fieldName
                                              : ""
                                          }}
                                        </h5>
                                        <div>
                                          <button
                                            ngbAccordionToggle
                                            class="btn btn-dark-blue"
                                            (click)="
                                              editSegmentMapping(importFile, i)
                                            "
                                            title="View/Edit"
                                          >
                                            <fa-icon
                                              [icon]="
                                                i ===
                                                importFile.openFieldChevronList
                                                  .customAttributeIndex
                                                  ? faChevronUp
                                                  : faChevronDown
                                              "
                                            ></fa-icon>
                                          </button>
                                          <button
                                            class="btn btn-dark-blue"
                                            (click)="
                                              removeSegmentMapping(
                                                importFile,
                                                i
                                              )
                                            "
                                            title="Remove"
                                          >
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div ngbAccordionCollapse>
                                      <h2>
                                        {{ field.fieldName }}
                                      </h2>
                                      <app-manifest-segment-mapping-form
                                        [index]="i"
                                        [segmentMapping]="
                                          importFile.segmentMapping[i]
                                        "
                                        (segmentMappingEvent)="
                                          handleSegmentMappingEvent(
                                            importFile,
                                            $event
                                          )
                                        "
                                      ></app-manifest-segment-mapping-form>
                                    </div>
                                  </div>
                                </div>
                                <h2>New Segment Mapping</h2>
                                <app-manifest-segment-mapping-form
                                  [index]="null"
                                  (segmentMappingEvent)="
                                    handleSegmentMappingEvent(
                                      importFile,
                                      $event
                                    )
                                  "
                                ></app-manifest-segment-mapping-form>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row"
                        [hidden]="!(importFile.importType !== 'transaction')"
                      >
                        <div class="col-lg-12">
                          <div class="ibox collapsed border-bottom">
                            <div
                              class="ibox-title collapse-link import-mapping-header"
                              (click)="resizeMe($event, 'memberRelationship')"
                            >
                              <h5>Relationships</h5>
                              <div class="ibox-tools">
                                <a>
                                  <fa-icon
                                    [icon]="
                                      chevronList['memberRelationship']
                                        ? faChevronUp
                                        : faChevronDown
                                    "
                                  ></fa-icon>
                                </a>
                              </div>
                            </div>
                            <form
                              method="get"
                              class="form-horizontal import-mapping-body"
                            >
                              <div class="ibox-content">
                                <div
                                  ngbAccordion
                                  #acc="ngbAccordion"
                                  [closeOthers]="true"
                                >
                                  <div
                                    ngbAccordionItem
                                    *ngFor="
                                      let field of importFile.memberRelationship;
                                      let i = index
                                    "
                                    id="static-{{ i }}"
                                  >
                                    <div ngbAccordionHeader>
                                      <div
                                        class="d-flex allign-items-center justify-content-between"
                                      >
                                        <h5
                                          class="ml-2 my-0 d-flex align-items-center"
                                        >
                                          Relationship
                                          {{
                                            field.relationshipName
                                              ? "- " + field.relationshipName
                                              : ""
                                          }}
                                        </h5>
                                        <div>
                                          <button
                                            ngbAccordionToggle
                                            class="btn btn-dark-blue"
                                            (click)="
                                              editMemberRelationship(
                                                importFile,
                                                i
                                              )
                                            "
                                            title="View/Edit"
                                          >
                                            <fa-icon
                                              [icon]="
                                                i ===
                                                importFile.openFieldChevronList
                                                  .memberMemberRelationship
                                                  ? faChevronUp
                                                  : faChevronDown
                                              "
                                            ></fa-icon>
                                          </button>
                                          <button
                                            class="btn btn-dark-blue"
                                            (click)="
                                              removeMemberRelationship(
                                                importFile,
                                                i
                                              )
                                            "
                                            title="Remove"
                                          >
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div ngbAccordionCollapse>
                                      <h2>
                                        {{ field.relationshipName }}
                                      </h2>
                                      <app-manifest-member-relationship-form
                                        [index]="i"
                                        [memberRelationship]="
                                          importFile.memberRelationship[i]
                                        "
                                        (memberRelationshipEvent)="
                                          handleMemberRelationshipEvent(
                                            importFile,
                                            $event
                                          )
                                        "
                                      ></app-manifest-member-relationship-form>
                                    </div>
                                  </div>
                                </div>
                                <h2>New Relationship</h2>
                                <app-manifest-member-relationship-form
                                  [index]="null"
                                  (memberRelationshipEvent)="
                                    handleMemberRelationshipEvent(
                                      importFile,
                                      $event
                                    )
                                  "
                                ></app-manifest-member-relationship-form>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </li>
            <li dndPlaceholderRef class="dndPlaceholder"></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="ibox collapsed border-bottom">
        <div
          class="ibox-title collapse-link"
          (click)="resizeMe($event, 'xmlPreview')"
        >
          <h5>JSON preview</h5>
          <div class="ibox-tools">
            <a>
              <fa-icon
                [icon]="chevronList['xmlPreview'] ? faChevronUp : faChevronDown"
              ></fa-icon>
            </a>
          </div>
        </div>
        <div class="ibox-content json-preview">
          <div class="row m-b-sm">
            <button
              id="preview-json"
              class="btn btn-dark-blue"
              type="button"
              (click)="updateJSON()"
              title="Refresh"
            >
              <fa-icon [icon]="faRedo"></fa-icon>
            </button>
            <small>Click "Refresh" to update.</small>
          </div>
          <div class="row">
            <pre ng-bind="rawJSON" class="w-100"
              >{{ rawJSON }}
              </pre
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
