import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from './../../services/helpers/response-helper.service';
import { StatusHelperService } from './../../services/helpers/status-helper.service';
import { IntegrationsService } from './../../services/integrations.service';
import { Integration } from './../../data/model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { IntegrationKeyService } from '../../services/integration-key.service';
import { IntegrationServerService } from '../../services/integration-server.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-integration-modal',
  templateUrl: './integration-modal.component.html',
  styleUrls: ['./integration-modal.component.scss'],
})
export class IntegrationModalComponent implements OnInit {
  @Input() public integration: Integration;
  @Input() public customers: any[];
  @Input() public filterChoices: any[];
  @Input() public isAdmin: boolean;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  title: string;
  newIntegrationForm: UntypedFormGroup;
  faTimes = faTimes;
  faSave = faSave;
  importTypes = ['Eligibility', 'Transaction', 'Organization', 'Multiple'];
  fileTypes = ['DELIMITED', 'FLAT', 'EDI'];
  keys: any[] = [];
  servers: any[] = [];
  message: string;

  constructor(
    private statusHelperService: StatusHelperService,
    private responseHelper: ResponseHelperService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder,
    private integrationsService: IntegrationsService,
    private integrationKeyService: IntegrationKeyService,
    private integrationServerService: IntegrationServerService
  ) {
    this.integrationKeyService
      .getIntegrationKeys({ includeKey: false })
      .subscribe((resp) => {
        if (resp.success) {
          this.keys = resp.entity.aaData;
        }
      });
    this.integrationServerService
      .getIntegrationServers(null)
      .subscribe((resp) => {
        if (resp.success) {
          this.servers = resp.entity.aaData;
        }
      });
  }

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    this.title = this.integration ? 'Edit Integration' : 'New Integration';
    this.message = this.integration
      ? 'Integration successfully edited'
      : 'Integration successfully created';
    if (this.integration) {
      this.newIntegrationForm = this.formBuilder.group({
        name: [this.integration.name, Validators.required],
        customerName: [this.integration.customerName, Validators.required],
        importType: [this.integration.importType, Validators.required],
        fileType: [this.integration.fileType, Validators.required],
        fileLayoutSchema: [this.integration.fileLayoutSchema],
        fileNamePattern: [
          this.integration.fileNamePattern,
          Validators.required,
        ],
        recordDelimiter: [this.integration.recordDelimiter],
        status: [this.integration.status, Validators.required],
        dateFormat: [this.integration.dateFormat],
        fieldCount: [this.integration.fieldCount, Validators.required],
        activationRealm: [this.integration.activationRealm],
        preprocessJob: [this.integration.preprocessJob],
        postprocessJob: [this.integration.postprocessJob],
        postUploadJobName: [this.integration.postUploadJobName],
        server: [this.integration.server],
        key: [this.integration.key],
        ignoreHeader: [this.integration.ignoreHeader],
        ignoreTrailer: [
          this.integration.ignoreTrailer,
          Validators.pattern('[0-9]*'),
        ],
        encodingCharset: [this.integration.encodingCharset],
        recordLength: [
          this.integration.recordLength,
          Validators.pattern('[0-9]*'),
        ],
        addsAndUpdates: [this.integration.addsAndUpdates],
        autoImport: [this.integration.autoImport],
        activateOnImport: [this.integration.activateOnImport],
        acceptUploads: [this.integration.acceptUploads],
      });
    } else {
      this.newIntegrationForm = this.formBuilder.group({
        name: ['', Validators.required],
        customerName: ['', Validators.required],
        importType: ['', Validators.required],
        fileType: ['', Validators.required],
        fileLayoutSchema: [''],
        fileNamePattern: ['', Validators.required],
        recordDelimiter: [''],
        status: ['', Validators.required],
        dateFormat: [''],
        fieldCount: ['', Validators.required],
        activationRealm: [''],
        preprocessJob: [''],
        postprocessJob: [''],
        postUploadJobName: [''],
        server: [null],
        key: [null],
        ignoreHeader: [''],
        ignoreTrailer: ['', Validators.pattern('[0-9]*')],
        encodingCharset: [''],
        recordLength: ['', Validators.pattern('[0-9]*')],
        addsAndUpdates: [false],
        autoImport: [false],
        activateOnImport: [false],
        acceptUploads: [false],
      });
    }
    if (!this.isAdmin) {
      this.newIntegrationForm.controls['postuploadJob'].disable();
    }
  }

  ok(): void {
    this.integrationsService
      .createOrUpdateIntegration(
        this.newIntegrationForm.value,
        this.integration?.id
      )
      .subscribe(
        () => {
          this.responseHelper.success(this.message);
          this.successEvent.emit();
        },
        (data: any) => {
          this.responseHelper.error(this, data.error.error, true);
        }
      );
  }

  selectServer(server): void {}

  cancel(): void {
    this.modalHelper.dismissAll();
  }
}
