import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subject} from 'rxjs';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-reward-amount-threshold',
  templateUrl: './reward-amount-threshold.component.html',
  styleUrls: ['./reward-amount-threshold.component.scss']
})
export class RewardAmountThresholdComponent implements OnInit {
    @Input() rewardAmountThreshold: any;
    periods: any;
    inclusionValues: any;
    trueFalse: any;
    faTimes = faTimes;
    faPlus = faPlus;
    @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
    debouncer: Subject<string> = new Subject<string>();
    @ViewChild(RegexSearchModalComponent) regexSearchModalComponent: RegexSearchModalComponent;

  constructor(
    private modalHelper: NgbModal,
  ) {
      this.debouncer
        .pipe(debounce(() => interval(1000)))
        .subscribe((value) => this.inputChangeEmitter.emit(value));
    }

  ngOnInit(): void {
    this.inclusionValues = [
       {'value' : 'strict',     'text': 'No'},
       {'value' : 'permissive',    'text': 'Yes'}
    ];

    this.periods = [
      {'value' : 'CAMPAIGN',     'text': 'CAMPAIGN'},
      {'value' : 'YEAR',     'text': 'YEAR'},
      {'value' : 'QUARTER',     'text': 'QUARTER'},
      {'value' : 'MONTH',    'text': 'MONTH'},
      {'value' : 'WEEK',     'text': 'WEEK'},
      {'value' : 'DAY',    'text': 'DAY'},
      { 'value' : 'PLANYEAR',     'text': 'PLANYEAR'},
      {'value' : 'CUSTOM',    'text': 'CUSTOM'}
    ];
  }

  regexSearchModal(searchFor: string, item: any, size: string, limitToOne: boolean, setValue: boolean, itemIndex: number): void {
    this.openRegexSearchModal(searchFor, item, size, limitToOne, setValue, itemIndex);
  }

  openRegexSearchModal(searchFor: string, item: any, size: string, limitToOne: boolean, setValue: boolean, itemIndex: number): void {
    const instance = this.modalHelper.open(RegexSearchModalComponent);
    instance.componentInstance.searchFor = searchFor;
    instance.componentInstance.item = item;
    instance.componentInstance.size = size;
    instance.componentInstance.limitToOne = limitToOne;
    instance.componentInstance.setValue = setValue;
    instance.componentInstance.itemIndex = itemIndex;
    instance.componentInstance.successEvent.subscribe((text: any) => {
      this.rewardAmountThreshold[itemIndex][item.name] = text;
      this.updateThresholds();
      instance.close();
    });
  }

  initThresholdObject(): void {
    var tmpObj = { 
      class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$RewardAmountThreshold",
      thresholdPeriod: { 
        class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$RewardAmountThreshold$ThresholdPeriod"
      }
    };
    if(this.rewardAmountThreshold){
      this.rewardAmountThreshold.push(tmpObj);
    } else {
      this.rewardAmountThreshold = [];
      this.rewardAmountThreshold.push(tmpObj);
    }
  }

  updateThresholds() {
    this.debouncer.next(this.rewardAmountThreshold);
  }

  removeThreshold(threshold) {
    this.rewardAmountThreshold.splice(this.rewardAmountThreshold.indexOf(threshold), 1);
    this.updateThresholds();
  }
}
